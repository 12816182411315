import React, {useState, useEffect, useContext} from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useSelfQuery,useTeamIndicesQuery,useIndicesQuery,useNotificationDeleteMutation,useNotificationPostMutation,useNotificationTestMutation,useNotificationPatchMutation } from '../../../store/indexOneApi';
import {set,get} from 'lodash/fp';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid2'
import { makeStyles } from '@mui/styles';
import { DataGrid,  GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Container from '@mui/material/Container';
import { LoadingButton } from '@mui/lab'
import Chip from '@mui/material/Chip'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip'
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import StageChip from '../../../components/elements/StageChip';
import { NotificationContext } from '../../elements/NotificationContext';

const sampleNotification = {
  index_id:'',
  owner_id:'',
  type:'email',
  table:'events'
}
const defaultNotification = {

}

const eventOptions = {
  'index_event':'Index Event',
  'index_value_eod':'EOD Index Value'
}

const streamTypes = {
  "value":"Index Value",
  "event":"Index Event",
  "tracker":"Tracker File",
  "weighting":"Weighting File",
  "alphathena":"Alphathena",
  "alphabot":"AlphaBot",
  "refinitiv":"Refinitiv",
  "stratifi":"Stratifi"
}

const deliveryTypes = {
  "email":"Email",
  "webhook":"WebHook",
  "s3":"S3",
  "sftp":"SFTP",
  "refinitiv":"Refinitiv"
}

export default function Streams(props) {
  const store = useStore().getState()
  const dispatch = useDispatch()
  const { createNotification } = useContext(NotificationContext);
  const [searchParams, setSearchParams] = useSearchParams()
  const [indices,setIndices] = useState([])
  const [streamDialogShow,setStreamDialogShow] = useState(false)
  const [editMode,setEditMode] = useState(false)
  const userId = useSelector(state => state.main.userData.id)
  const teamId = useSelector(state => state.main.teamData.id)
  const teamData = useSelector(state => state.main.teamData)
  const [emailError,setEmailError] = useState([false,""])
  const [emailRecipient,setEmailRecipient] = useState('')

  //new stream data
  const [indexSelected,setIndexSelected] = useState(null)
  const [name,setName] = useState('default name')
  const [deliveryType,setDeliveryType] = useState('email')
  const [triggerType,setTriggerType] = useState('event')
  const [streamType,setStreamType] = useState('tracker')
  const [cronSelected,setCronSelected] = useState('0 0 * * *')
  const [eventSelected,setEventSelected] = useState('index_value_eod')
  const [emailRecipients,setEmailRecipients] = useState([])
  //webhook settings
  const [headers,setHeaders] = useState({"x-api-key":""})
  const [url,setUrl] = useState("https://api.alphathena.com/saved-index")
  const [alphathenaId,setalphathenaId] = useState("")
  const [alphathenaUser,setalphathenaUser] = useState("")
  const [alphaBotInstrumentId,setAlphaBotInstrumentId] = useState("")
  const [method,setMethod] = useState("POST")
  //refinitiv
  const [ric,setRic] = useState("")

  //querydata
  const {data:teamIndicesData=[],isLoading:teamIndicesIsLoading} = useTeamIndicesQuery({"id":teamData.id})
  const {data:indicesData=[],isLoading:indicesIsLoading} = useIndicesQuery()
  const {data:streamsData = {notifications:[]},isLoading:streamsIsLoading} = useSelfQuery({"team_id":teamData.id,"full_notifications":true})
  //edit_only
  const [notificationId,setNotificationId] = useState(null)

  //apis
  const [postTrigger,{isLoading:postIsLoading}] = useNotificationPostMutation()
  const [patchTrigger,{isLoading:patchIsLoading}] = useNotificationPatchMutation()
  const [testTrigger,{isLoading:testIsLoading}] = useNotificationTestMutation()
  const [deleteTrigger,{isLoading:deleteIsLoading}] = useNotificationDeleteMutation()

  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:'streams'})
    document.title = `Index One | Streams`
  })()},[])

  useEffect(() => {(async()=>{
    if(teamIndicesIsLoading || indicesIsLoading){
      return
    }
    var indexArray = teamIndicesData.concat(indicesData)
    var options = {}
    for (var i = 0; i < indexArray.length; i++) {
      options[`${indexArray[i].id}-${indexArray[i].version}`] = indexArray[i].name
    }
    const optionsSorted = Object.fromEntries(
      Object.entries(options).sort((a, b) => a[1].localeCompare(b[1]))
    );
    console.log(Object.entries(options).sort((a, b) => a[1].localeCompare(b[1])))
    console.log(optionsSorted)
    setIndices(optionsSorted)

    var startIndex = searchParams.get("indexId")
    if(startIndex){
      if(options.hasOwnProperty(startIndex)){
        setStreamDialogShow(true)
        setIndexSelected(startIndex)
      }
    }
  })()},[indicesData,teamIndicesData])

  useEffect(() => {
    if (!streamDialogShow) {
      setEditMode(false);
    }
  }, [streamDialogShow]);


  const handleEdit = (rowData) => {
    setEditMode(true)

    const keyMappings = {
      'index_id': setIndexSelected,
      'name': setName,
      'delivery_type': setDeliveryType,
      'trigger_type': setTriggerType,
      'payload_type': setStreamType,
      'trigger_schedule': setCronSelected,
      'trigger_event': setEventSelected,
      'email_recipients': setEmailRecipients,
      'headers': setHeaders,
      'url': setUrl,
      'method': setMethod,
      'alphathena_index_uuid': setalphathenaId,
      'alphathena_user': setalphathenaUser,
      'alphabot_instrument_id': setAlphaBotInstrumentId,
      'ric': setRic,
      'notification_id': setNotificationId
    };

    for (const [key, setter] of Object.entries(keyMappings)) {
      if (rowData[key] !== undefined) {
        setter(rowData[key]);
      }
    }

    setStreamDialogShow(true)
  }

  const changeStreamType = (value) => {
    if (value == 'alphathena'){
      setUrl("https://api.alphathena.com/saved-index")
      setMethod("PUT")
      setHeaders({"x-api-key":""})
      setDeliveryType("webhook")
    } else if (value == 'alphabot'){
      setUrl("https://alphabot.net:443/alphabot-public-api/rest/v2/import")
      setMethod("PUT")
      setHeaders({})
      setDeliveryType("webhook")
    } else if (value == 'stratifi'){
      setDeliveryType('sftp')
    }
    setStreamType(value)
  }

  const handleSubmit = async (simulate=false,edit=false) => {
    //if there's email recipient in the text box, try to include it

    var recipientsArray = JSON.parse(JSON.stringify(emailRecipients));

    if(emailRecipient.length > 0 ){
      addEmailRecipient("Enter",emailRecipient);
      recipientsArray.push(emailRecipient);
    }
    var body = {
      'index_id':indexSelected,
      'name':name,
      'delivery_type':deliveryType,
      'trigger_type':triggerType,
      'payload_type':streamType,
      'trigger_schedule':cronSelected,
      'trigger_event':eventSelected
    };
    //add required attrs
    body.user_id = userId;
    body.team_id = teamId;
    
    if(deliveryType == 'email'){
      body.email_recipients = recipientsArray;
    } else if (deliveryType == 'webhook'){
      body.headers = headers;
      body.url = url;
      body.method = method;
      if(streamType == 'alphathena'){
        body.alphathena_index_uuid = alphathenaId;
        body.alphathena_user = alphathenaUser;
      } else if (streamType == "alphabot"){
        body.alphabot_instrument_id = alphaBotInstrumentId;
      }
    } else if (deliveryType == 'refinitiv'){
      body.ric = ric;
    }
    if(edit){
      body.notification_id = notificationId
      try{
        const patchResponse = await patchTrigger(body);
        createNotification({message: "Stream edited successfully.",status: 'success'});
      }catch(e){
        console.log(e);
        createNotification({message: "Failed to edit stream.",status: 'error'});
      }
    } else if(!simulate){
      //storing notification
      try{
        const postResponse = await postTrigger(body);
        createNotification({message: "Stream created successfully.",status: 'success'});
      }catch(e){
        console.log(e);
        createNotification({message: "Failed to create stream.",status: 'error'});
        //setLoading(false)
      }
    } else {
      //simulating notification
      try{
        const testResponse = await testTrigger({notifications:[body]});
        console.log("testResponse");
        console.log(testResponse);
        let status = testResponse.data[0].last_execution_status
        if (status == 'success'){
          createNotification({message: "Test delivery successful.",status: 'success'});
        } else {
          createNotification({message: testResponse.data[0].last_execution_message,status: 'error'});
        }
        
        //api.post('notifications/run',{notifications:[body]}).then(r => {
        //  console.log(r)
        //  setLoading(false)
        //})
      }catch(e){
        console.log(e);
        createNotification({message: "Test delivery failed.",status: 'error'});
        //setLoading(false)
      }
    }
  }

  function addEmailRecipient(keyCode,email){
    if(keyCode == "Enter"){
      if(email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$/g)){
        setEmailRecipients(oldArray => [...oldArray, email]);
        setEmailRecipient("");
      } else {
        setEmailError([true,"Invalid email address."]);
      }
    }
  }

  const handleDelete = async (index_id,notification_id) => {
    try{
      const deleteBody = {
        indexId:index_id,
        notificationId:notification_id
      }
      const deleteResponse = await deleteTrigger(deleteBody)
      console.log(deleteResponse)
      /*
      api.delete(`notifications/${index_id}/${notification_id}`).then(r => {
        var prevStreams = JSON.parse(JSON.stringify(streams))
        for(let i = 0, l = prevStreams.length; i < l; ++i) {
          console.log(prevStreams[i])
          console.log(notification_id)
          if (prevStreams[i].notification_id == notification_id){
            //TODO: CHECK HOW TO REMOVE AT INDX
            prevStreams.splice(i,1)
            console.log(prevStreams)
            setStreams(prevStreams)
            break
          }
        }
      })
      */
    }catch(e){
      console.log(e)
    }
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Stream Name',
      flex:2,
    },
    {
      field: 'index_name',
      headerName: 'Index Name',
      flex:2,
      valueGetter: (value,row) =>{
        try{
          return(indices[row.index_id] || '')
        } catch {
          return("")
        }
      }
    },
    {
      field: 'last_execution_status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.last_execution_message || 'No message'}>
        <div>{StageChip(params.row.last_execution_status || 'N/A')}</div>
          </Tooltip>
        );
      }
    },
    {
      field: 'last_execution_time',
      headerName: 'Last Execution',
      flex: 1,
      valueGetter: (value,row) => {
        return row.last_execution_time || 'N/A';
      }
    },
    { field: 'notification_id', headerName: 'Stream ID', flex:1},
    { field: 'index_id', headerName: 'Index ID', flex:1},
    { field: 'delivery_type', headerName: 'Delivery',flex:1},
    { field: 'payload_type', headerName: 'Payload',flex:1},
    { field: 'trigger_type', headerName: 'Trigger',flex:1},
    {
      field: 'action',
      headerName: 'Action',
      width:200,
      renderCell: (params) => (
          <div>
            <Tooltip title="Delete"><IconButton disabled={deleteIsLoading} onClick={e=>handleDelete(params.row.index_id,params.row.notification_id)}><DeleteIcon fontSize='small'/></IconButton></Tooltip>
          <Tooltip title="Edit">
            <IconButton disabled={deleteIsLoading} onClick={e => handleEdit(params.row)}>
              <EditIcon fontSize='small' />
            </IconButton>
          </Tooltip>
          </div>
      )
    }
  ]

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
        <div style={{flex:1}}/>
        <Button size='small' startIcon={<AddIcon />} onClick={e=>setStreamDialogShow(true)}>Create Stream</Button>
      </GridToolbarContainer>
    );
  }

  const returnStreamDialog = () => {
    return (
      (<Dialog scroll='paper' open={streamDialogShow} onClose={e=>setStreamDialogShow(false)} maxWidth='sm' fullWidth>
        <DialogTitle>
          Stream Specifications
          <IconButton
            aria-label="close"
            onClick={e=>setStreamDialogShow(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}>
              <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
        <Stack direction='column' spacing={3}>
          <Stack direction='row' spacing={1} sx={{width:'100%'}}>
          <TextField
            size='small'
            id="outlined-select-currency"
            select
            fullWidth
            label="Index Name"
            value={indexSelected}
            onChange={e=>setIndexSelected(e.target.value)}
            helperText="Select Index"
          >
            {Object.entries(indices).map(([id,name]) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            size='small'
            id="outlined-select-currency"
            label="Stream Name"
            fullWidth
            value={name}
            onChange={e=>setName(e.target.value)}
            helperText="Nickname for the stream"
          />
          </Stack>

          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Delivery Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={deliveryType}
              onChange={e=>setDeliveryType(e.target.value)}
            >
              {Object.entries(deliveryTypes).map(([k,v])=>
                <FormControlLabel value={k} control={<Radio />} label={v}/>
              )}
            </RadioGroup>
          </FormControl>
          
          <Stack direction='row' alignItems='flex-end'>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Trigger Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={triggerType}
                onChange={e=>setTriggerType(e.target.value)}
              >
                <FormControlLabel value="cron" control={<Radio />} label="Schedule" />
                <FormControlLabel value="event" control={<Radio />} label="Event" />
              </RadioGroup>
            </FormControl>
            {triggerType == 'event' &&
              <TextField
                size='small'
                id="outlined-select-currency"
                select
                label="Select"
                value={eventSelected}
                onChange={e=>setEventSelected(e.target.value)}
              >
                {Object.entries(eventOptions).map(([id,name]) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            }
            {triggerType == 'cron' &&
              <TextField
                size='small'
                id="outlined-select-currency"
                value={cronSelected}
                onChange={e=>setCronSelected(e.target.value)}
              />
            }
          </Stack>

          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Stream Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={streamType}
              onChange={e=>changeStreamType(e.target.value)}
            >
              {Object.entries(streamTypes).map(([k,v])=>
                <FormControlLabel value={k} control={<Radio />} label={v} />
              )}
            </RadioGroup>
          </FormControl>

        {deliveryType == 'email' ?
          <Stack spacing={1}>
            <Typography>Email Recipients</Typography>
            <Grid container spacing={1} alignItems='flex-start'>
              <Grid size={4}>
                <TextField
                  size='small'
                  label="Press enter to save email"
                  onChange={e=>{setEmailRecipient(e.target.value);setEmailError([false,""])}}
                  value={emailRecipient}
                  onKeyDown={e=>addEmailRecipient(e.code,e.target.value)}
                  error={emailError[0]}
                  helperText={emailError[1]}
                />
              </Grid>
              <Grid size={7}>
                <Grid container spacing={1}>
                  {emailRecipients.map((email,idx)=>(
                    <Grid><Chip label={email} onDelete={e=>setEmailRecipients((prevState) => prevState.filter((prevItem,i) => i !== idx))} /></Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        : deliveryType == 'webhook' ?
          <Stack spacing={1}>
            <Typography>Webhook Settings</Typography>
                <TextField
                  size='small'
                  label="Webhook URL"
                  onChange={e=>setUrl(e.target.value)}
                  value={url}
                />
                <TextField
                  size='small'
                  label="HTTP Method"
                  onChange={e=>setMethod(e.target.value)}
                  value={method}
                />
                {streamType === "alphathena" && <TextField
                  size='small'
                  label="Alphathena Index ID"
                  onChange={e=>setalphathenaId(e.target.value)}
                  value={alphathenaId}
                />}
                {streamType === "alphathena" && <TextField
                  size='small'
                  label="Alphathena User ID"
                  onChange={e=>setalphathenaUser(e.target.value)}
                  value={alphathenaUser}
                />}
                {streamType === "alphabot" && <TextField
                  size='small'
                  label="Alphabot Instrument ID"
                  onChange={e=>setAlphaBotInstrumentId(e.target.value)}
                  value={alphaBotInstrumentId}
                />}
                <Typography>Request Headers</Typography>
                {Object.entries(headers).map(([k,v]) =>(
                    <Stack direction='row'>
                      <TextField
                        size='small'
                        label="Key"
                        onChange={e=>setHeaders((prevState) => Object.fromEntries(Object.entries(prevState).map(([key, value]) => [key === k ? e.target.value : key, value])))}
                        value={k}
                      />
                      <TextField
                        size='small'
                        label="Value"
                        onChange={e=>setHeaders((prevState) => ({ ...prevState, [k]: e.target.value }))}
                        value={v}
                      />
                      <Button onClick={e=>setHeaders(({ [k]: _, ...rest }) => rest)}>x</Button>
                    </Stack>
                ))}
                <Button onClick={e=>setHeaders((prevState) => ({ ...prevState, "":"" }))}>+</Button>
          </Stack>
        : deliveryType == 'refinitiv' ?
          <TextField
            size='small'
            label="Refinitiv RIC"
            onChange={e=>setRic(e.target.value)}
            value={ric}
          />
        : ''
        }
          <Stack direction='row' spacing={2}>
            {editMode?
              <>
              <LoadingButton fullWidth variant='contained' loading={patchIsLoading} onClick={e=>handleSubmit(false,true)}>Edit Stream</LoadingButton>
              </>
              :
              <>
              <LoadingButton fullWidth variant='contained' loading={postIsLoading} onClick={e=>handleSubmit(false,false)}>Create Stream</LoadingButton>
              <LoadingButton fullWidth variant='outlined' loading={testIsLoading} onClick={e=>handleSubmit(true,false)}>Test Delivery</LoadingButton>
              </>
            }
          </Stack>
        </Stack>
        </DialogContent>
      </Dialog>)
    );
  }



  return (
    <Container maxWidth={false} disableGutters style={{padding:'2rem', backgroundColor:'#fafafa'}}>
      {returnStreamDialog()}
      <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid
                loading={streamsIsLoading}
                rows={streamsData.notifications}
                columns={columns}
                getRowId={(row) => row.notification_id}
                autoPageSize
                disableMultipleSelection={true}
                slots={{
                  toolbar: CustomToolbar,
                }}
            />
      </div>
    </Container>
  );
}